import { STATUS } from './constants'

export const statusOptions = new Map([
    [STATUS.DRAFT, { text: 'Draft', id: 'draft' }],
    [STATUS.SENT, { text: 'Sent', id: 'sent' }],
    [STATUS.PROCESSING, { text: 'Processing', id: 'processing' }],
    [STATUS.ACTIVE, { text: 'Active', id: 'active' }],
    [STATUS.PENDING, { text: 'Pending', id: 'pending' }],
    [STATUS.HOLD, { text: 'Hold', id: 'hold' }],
    [STATUS.CANCELED, { text: 'Canceled', id: 'canceled' }],
    [STATUS.CLOSED, { text: 'Closed', id: 'closed' }]
])
