import type { ListingStatus } from '~/types/Properties'

export const STATUS: Record<string, ListingStatus> = {
    DRAFT: 'draft',
    SENT: 'sent',
    PROCESSING: 'processing',
    ACTIVE: 'active',
    PENDING: 'pending',
    HOLD: 'hold',
    CLOSED: 'closed',
    CANCELED: 'canceled'
}
